import React, { useState } from "react";
import AboutUsPhoto from "../../img/about.jpg";
import CountUp from "react-countup";
import Logo from "../../img/logo.png";

import "./styles.scss";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const [countUpFinish, setCountFinish] = useState(false);

  return (
    <div className="o-nama">
      <div className="container-fluid screen-head mb-5 py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3">O Kompaniji</h1>
        </div>
      </div>

      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container about px-lg-0">
          <div className="row mx-lg-0 g-0">
            <div className="col-lg-6 ps-lg-0" style={{ minHeight: "400px" }}>
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src={AboutUsPhoto}
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text py-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 pe-lg-0">
                <div className="screen-top-title text-start">
                  <h1 className="display-5 mb-4">O Nama</h1>
                </div>
                <p className="pb-2 mb-4 company-text">
                  👉 <span className="company-name">"KOP-GRAČANIN"</span> vam
                  pruža usluge kopanja i pripreme zemljišta uz korištenje
                  kamiona i bagera, koji omogućavaju prilagođavanje različitim
                  vrstama terena i zahtjevima klijenata. <br />
                  Trudimo se pružiti kvalitetne usluge po konkurentnim cijenama,
                  te je usmjerena na zadovoljstvo svojih klijenata. <br />
                  Na sajtu možete pogledati neke od naših projekata, u sekciji{" "}
                  <span className="usluge-text">
                    <Link to="/usluge">Usluge</Link>
                  </span>
                  .
                </p>
                <div className="row mb-4 g-4 pb-2">
                  <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                    <div className="d-flex align-items-center">
                      <div className="logo-wrapper">
                        <img src={Logo} alt="company logo" />
                      </div>
                      <div className="ms-3">
                        <h2 className="text-primary mb-1 broj-projekata">
                          <CountUp
                            start={0}
                            end={2100}
                            duration={5}
                            onEnd={() => setCountFinish(true)}
                          />
                          {countUpFinish ? <span>+</span> : null}
                        </h2>
                        <p className="mb-0 broj-uspjesnih-projekata">
                          Upješnih projekata
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
