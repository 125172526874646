import React, { useState } from "react";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import "./styles.scss";

import Logo from '../src/img/logo.png'

const Header = () => {

  const location = useLocation();

  const tab = location.pathname === '/usluge' ? 'about-us' : 'home'

  const [isCollapsed, setIsCollapsed] = useState(true); // Set navbar to collapsed by default

  return (
    <div className="header">
      <div class="container-fluid bg-light p-0">
        <div class="row gx-0 d-none d-lg-flex">
          <div class="col-lg-7 px-5 text-start">
            <div className='logo-wrapper'>
              <img src={Logo} alt='company logo' />
            </div>
          </div>
          <div class="col-lg-5 px-5 text-end">
            <div class="h-100 d-inline-flex align-items-center py-3 me-4 phone-numbers">
              <div className="phone-icon-wrapper"><FaPhone /></div>
              <small>065/870-588</small>
              <div class="phone-divider vr"></div>
              <small>066/413-510</small>
            </div>
            <div class="h-100 d-inline-flex align-items-center">
              <a class="btn btn-sm-square bg-white text-primary me-1" href="https://www.facebook.com/profile.php?id=61555790314916" target="_blank">
                <FaFacebookSquare />
              </a>
              <a class="btn btn-sm-square bg-white text-primary me-0" href="https://www.instagram.com/_kop_gracanin_/" target="_blank">
                <FaInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>

      <nav class="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
        <button
          type="button"
          class="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarHeadCollapse"
          onClick={() => setIsCollapsed(!isCollapsed)} // Toggle collapse state on click
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${!isCollapsed ? '' : 'show'}`} id="navbarCollapse">
          <div class="navbar-nav ms-auto p-4 p-lg-0">
            <Link to="/" className={`nav-item nav-link ${tab === 'home' ? 'active' : ''}`}>
              Početna
            </Link>
            <Link to='/usluge' className={`nav-item nav-link ${tab === 'about-us' ? 'active' : ''}`}>
              Usluge
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
