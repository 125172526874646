import React from "react";
import iskop1 from "../../img/iskop/iskop1.jpg";
import iskop2 from "../../img/iskop/iskop2.jpg";
import iskop3 from "../../img/iskop/iskop3.jpg";
import iskop4 from "../../img/iskop/iskop4.jpg";
import priprema1 from "../../img/priprema_podloga/priprema1.jpg";
import priprema2 from "../../img/priprema_podloga/priprema2.jpg";
import priprema3 from "../../img/priprema_podloga/priprema3.jpg";
import priprema4 from "../../img/priprema_podloga/priprema4.jpg";
import sahta1 from "../../img/betonska_sahta/sahta1.jpg";
import sahta2 from "../../img/betonska_sahta/sahta2.jpg";
import sahta3 from "../../img/betonska_sahta/sahta3.jpg";
import drenaza1 from "../../img/drenaza/drenaza1.jpg";
import drenaza2 from "../../img/drenaza/drenaza2.jpg";
import septicka1 from "../../img/septicka/septicka1.jpg";
import septicka2 from "../../img/septicka/septicka2.jpg";
import krcenje1 from "../../img/krcenje/k1.jpg";
import krcenje2 from "../../img/krcenje/k2.jpg";
import krcenje3 from "../../img/krcenje/k3.jpg";
import dostava1 from "../../img/dostava_zemlje/d1.jpg";
import dostava2 from "../../img/dostava_zemlje/d2.jpg";
import dostava3 from "../../img/dostava_zemlje/d3.jpg";
import CustomCarousel from "../customCarousel";
import "./styles.scss";

const Services = () => {
  return (
    <div className="services">
      <div className="container-fluid screen-head mb-5 py-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3">
            Iskustva
          </h1>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="screen-top-title text-center">
            <h1 className="display-5 mb-5">Naši Radovi</h1>
          </div>
          <div className="row g-4 portfolio-container image-wrapper">
            <div
              className="col-lg-4 col-md-6 first wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="rounded overflow-hidden">
                <div className="position-relative overflow-hidden">
                  <CustomCarousel
                    images={[
                      iskop1,
                      iskop2,
                      iskop3,
                      iskop4,
                      iskop1,
                      iskop2,
                      iskop3,
                      iskop4,
                    ]}
                  />
                </div>
                <div className="border border-light border-top-0 p-4 image-legend border-5">
                  <p className="text-primary fw-medium mb-2 naslov naslov">Iskop</p>
                  <h5 className="opis-slike">
                    Iskop, uklanjanje dijela tla radi gradnje gradjevine
                    <br />
                    Površinski(otkop sloja humusa), široki otkop s bočnim
                    nagibom, kanala i sl.
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 second wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="rounded overflow-hidden">
                <div className="position-relative overflow-hidden">
                  <CustomCarousel
                    images={[priprema1, priprema2, priprema3, priprema4]}
                  />
                </div>
                <div className="border border-top-0 p-4 image-legend border-5 border-light">
                  <p className="text-primary fw-medium mb-2 naslov">Priprema Podloga</p>
                  <h5 className="opis-slike">
                    Priprema različitih podloga, nasipanje, ravnanje i zbijanje
                    istih Probijanje i nasipanje puteva, parking(dvorišta),
                    priprema podloge za asfalt, betonske kocke i betonske ploče
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 first wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="rounded overflow-hidden">
                <div className="position-relative overflow-hidden">
                  <CustomCarousel images={[sahta1, sahta2, sahta3]} />
                </div>
                <div className="border border-light border-top-0 p-4 image-legend border-5">
                  <p className="text-primary fw-medium mb-2 naslov">Šahtovi</p>
                  <h5 className="opis-slike">
                    Ugradnja betonske šahte po standardu Vodovod-a A.D. Banja
                    Luka Dimenzije: Unutrašnje 120x100x120 Vanjska
                    140x120x130(sa poklopcem) 140x120x140(teretni šaht sa
                    poklopcem)
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 second wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="rounded overflow-hidden">
                <div className="position-relative overflow-hidden">
                  <CustomCarousel images={[drenaza1, drenaza2]} />
                </div>
                <div className="border border-5 border-top-0 p-4 image-legend border-light">
                  <p className="text-primary fw-medium mb-2 naslov">Drenaža</p>
                  <h5 className="opis-slike">
                    Drenaža, postupak za sakupljanje i odvodjenje podzemnih voda
                    iz zemljišta pomoću kanala i drenažnih cijevi Zaštita zidova
                    od vlage, kod nasipa i usjeka radi sprječavanja klizanje
                    zemljišta
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 first wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="rounded overflow-hidden">
                <div className="position-relative overflow-hidden">
                  <CustomCarousel images={[septicka1, septicka2]} />
                </div>
                <div className="border border-top-0 p-4 image-legend border-5 border-light">
                  <p className="text-primary fw-medium mb-2 naslov">Septička</p>
                  <h5 className="opis-slike">
                    Septička jama, u područjima na kojima nema javne
                    kanalizacijske mreže, izvodimo postavljanje septičke jame za
                    prihvat fekalija i otpadnih voda radi smanjena zaraznih
                    bolesti.
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 second wow"
              data-wow-delay="0.5s"
            >
              <div className="rounded overflow-hidden">
                <div className="position-relative overflow-hidden">
                  <CustomCarousel images={[krcenje1, krcenje2, krcenje3]} />
                </div>
                <div className="border border-top-0 p-4 image-legend border-light border-5">
                  <p className="text-primary fw-medium mb-2 naslov">Krčenje</p>
                  <h5 className="opis-slike">
                  Krčenje zemljišta (placeva) zaraslog u šiblje i korov, čupanje panjeva
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 second wow"
              data-wow-delay="0.5s"
            >
              <div className="rounded overflow-hidden">
                <div className="position-relative overflow-hidden">
                  <CustomCarousel images={[dostava1, dostava2, dostava3]} />
                </div>
                <div className="border border-light border-top-0 p-4 image-legend border-5">
                  <p className="text-primary fw-medium mb-2 naslov">Prevoz</p>
                  <h5 className="opis-slike">
                  Prevoz(dostava) zemlje, šljunka, kamena i ostalog gradjevinskog materijala
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
