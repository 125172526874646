import "./App.css";
import "./styles.scss";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Services from "./components/services";
import AboutUs from "./components/aboutUs";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: (
        <div>
          <Header /> <AboutUs />
          <Footer />
        </div>
      ),
    },
    {
      path: "/index",
      element: (
        <div>
          <Header /> <AboutUs />
          <Footer />
        </div>
      ),
    },
    {
      path: "/usluge",
      element: (
        <div>
          <Header /> <Services /> <Footer />
        </div>
      ),
    },
  ],
);

function App() {
  return (
    <div>
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
