import React from "react";
import { LuMapPin } from "react-icons/lu";
import { MdOutlinePhoneEnabled } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "../src/img/logo.png";

const Footer = () => {
  return (
    <div
      className="container-fluid bg-dark text-light footer mt-5 pt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Kontakt</h4>
            <p className="mb-2 contact-info">
              <div className="me-1">
                <LuMapPin />
              </div>
              Banja Luka
            </p>
            <p className="mb-2 contact-info phone">
              <div className="d-flex">
                <div className="me-1">
                  <MdOutlinePhoneEnabled />
                </div>
                Kontakt telefoni:
              </div>{" "}
              <div className="telefoni">
                <span className="telefon">065/870-588</span> <br />{" "}
                <span className="telefon">066/413-510</span> <br /> (Viber,
                WhatsApp){" "}
              </div>
            </p>
            <p className="mb-2 contact-info">
              <div className="me-1">
                <HiOutlineMail />
              </div>
              E-Mail: kopgracanin@gmail.com
            </p>

            <div className="d-flex pt-2">
              <a
                class="btn btn-sm-square bg-white text-primary me-1"
                href="https://www.facebook.com/profile.php?id=61555790314916"
                target="_blank"
              >
                <FaFacebookSquare />
              </a>
              <a
                class="btn btn-sm-square bg-white text-primary me-0"
                href="https://www.instagram.com/_kop_gracanin_/"
                target="_blank"
              >
                <FaInstagram />
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Linkovi</h4>
            <Link to="/" class="nav-item nav-link active">
              Početna
            </Link>
            <Link to="/usluge" class="btn btn-link">
              Usluge
            </Link>
            <div className="d-none d-md-block mt-5">
            <h4>KOP-GRAČANIN</h4>
              <div className="logo-wrapper">
                <img src={Logo} alt="company logo" />
              </div>
            </div>
          </div>
          <div className="col-lg-3 text-center col-md-6 d-md-none ">
            <h4>KOP-GRAČANIN</h4>
            <div className="logo-wrapper">
              <img src={Logo} alt="company logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
