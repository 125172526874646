import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './styles.scss'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const CustomCarousel = ({ images = [] }) => {
  return (
    <div className="custom-carousel">
      <Carousel responsive={responsive}
        swipeable
        draggable
        autoPlay
        infinite
        itemClass="my-image"
      >
        {images.map((image, i) => {
          return (
            <div className="carousel-image">
                <img src={image} alt="slika-rada"/>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default CustomCarousel;
